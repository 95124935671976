<template>
    <v-container class="pa-0" fluid>
        <MglMap :style="cssProps" @load="load" ref="map" v-if="ready" :zoom="zoom" :center="coordinates" style="min-height: 400px;" :accessToken="accessToken" :mapStyle="mapStyle" :preserveDrawingBuffer="true" :scrollZoom="scroll">
            <MglGeojsonLayer
                v-if="prospects"
                :sourceId="geoSource.data.id"
                :source="geoSource"
                :layerId="geoJsonLayer.id"
                :layer="geoJsonLayer"
                @added="geoJsonReady"
                ref="layer1"
            />
            <MglGeojsonLayer
                v-if="prospects"
                :sourceId="geoSource2.data.id"
                :source="geoSource2"
                :layerId="geoJsonLayer2.id"
                :layer="geoJsonLayer2"
                @added="geoJsonReady"
                ref="layer2"
            />
            <MglMarker ref="marker" v-if="initialMapClick" :coordinates="markerCoords" color="#015a9c" anchor="bottom"  />
        </MglMap>
    </v-container>
</template>

<script>
import Mapbox from "mapbox-gl"
import { MglMap, MglGeojsonLayer, MglMarker } from "vue-mapbox"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import bbox from "@turf/bbox"
import combine from "@turf/combine"
import flatten from "@turf/flatten"
import explode from "@turf/explode"
import union from "@turf/union"
import center from "@turf/center"
import area from "@turf/area"
import { multiPolygon, featureCollection, feature, convertArea } from "@turf/helpers"
export default {
    name: "Map",
    components: {
        MglMap,
        MglGeojsonLayer,
        MglMarker
    },
    props: ["growerId", "growerIds", "mapCoords", "select", "draw", "add", "mapFields", "report", "scrollZoom"],
    data() {
        return {
            cursor: "pointer",
            box: "",
            loaded: false,
            startingFeatures: [],
            markerCoords: [0,0],
            customerGeo: {
                features: [],
                type: "FeatureCollection"
            },
            accessToken: "pk.eyJ1IjoibmV0Y2VudGVyZmFyZ28iLCJhIjoiY2tuODVtbjlvMGtqMjJvbzA0ZDJzbWlleCJ9._yGaWiegbU46R07pozv5Hw",
            mapStyle: "mapbox://styles/netcenterfargo/ckp7a0jcb0t4417psj2vxgyiq",
            coordinates: [-98, 46],
            geoJsonSource: {
                type: "geojson"
            },
            geoJsonLayer: {
                id: "fill",
                type: "fill",
                paint: {
                    "fill-color": ['get', 'color'],
                    "fill-opacity": .5,
                    "fill-outline-color": "#ffffff"
                }
            },
            geoJsonLayer2: {
                id: "name",
                type: "symbol",
                layout: {
                    "text-field": ['get', 'title'],
                    "text-font": ['Oswald Regular']
                },
                paint: {
                    "text-color": "#ffffff"
                }
            },
            zoom: 0,
            initialMapClick: false,
            defaultMapSettings: "",
            ready: false,
            prospect: false,
            prospects: false,
            drawBox: null,
            colors: ["#fe4f20", "#3e37f3", "#9c0183", "#95e2c2", "#2e6f67", "#56b3df", "#a43105", "#b454fd", "#0ff999", "#918f7e"],
        }
    },
    async created() {
        if (this.growerId) {
            await this.startUp()
        }
        else if (this.mapFields) {
            this.prospects = true
            this.startingFeatures = JSON.parse(JSON.stringify(this.mapFields))
            this.customerGeo.features = this.mapFields
        }
        else {
            this.zoom = 4
            this.prospect = true
        }
        this.ready = true
    },
    computed: {
        scroll() {
            if (this.scrollZoom) {
                return false
            }
            else return true
        },
        cssProps() {
            return {
                '--cursor-type': this.cursor
            }
        },
        geoSource() {
            let source = JSON.parse(JSON.stringify(this.geoJsonSource))
            source.data = JSON.parse(JSON.stringify(this.customerGeo))
            source.data.id = "customer"
            return source
        },
        geoSource2() {
            let source = JSON.parse(JSON.stringify(this.geoJsonSource))
            source.data = JSON.parse(JSON.stringify(this.customerGeo))
            source.data.id = "points"
            return source
        }
    },
    watch: {
        mapCoords(val) {
            if (val === false) {
                this.$refs.map.actions.flyTo({center: this.defaultMapSettings.center, zoom: this.defaultMapSettings.zoom})
            }
            else if (val === "exit") {
                this.$refs.map.actions.fitBounds(this.box, {
                    padding: {top: 20, bottom:20, left: 20, right: 20}
                })
            }
            else {
                this.fitField(val)
                // this.$refs.map.actions.flyTo({center: val.center, zoom: 15})
            }
        },
        async growerId(val) {
            this.prospects = true
            let fields = await this.$store.dispatch("plan/getGrowerGeoData", val.Grower_Id)
            this.setData(fields)
            this.load()
        },
        growerIds(val) {
            this.prospects = true
            this.customerGeo.features = this.collectFields(val)
            this.setData(this.customerGeo, false)
        }
    },
    methods: {
        async startPlan(growerId) {
            let fields = await this.$store.dispatch("plan/getGrowerGeoData", growerId)
            return this.setData(fields)
        },
        collectFields(fields) {
            return [].concat(...fields.map((item,ind) => {
                let custFields = item.Fields.map(item2 => {
                    item2.Geo.features[0].properties.title = item.Name
                    item2.Geo.features[0].properties.color = this.colors[ind]
                    return item2.Geo.features
                })
                return [].concat(...custFields)
            }))
        },
        async startUp() {
            this.prospects = true
            let fields = await this.$store.dispatch("plan/getGrowerGeoData", this.growerId)
            this.startingFeatures = JSON.parse(JSON.stringify(fields))
            this.setData(fields)
        },
        reset() {
            // this.setData(JSON.parse(JSON.stringify(this.startingFeatures)))
            this.customerGeo.features = JSON.parse(JSON.stringify(this.startingFeatures))
            this.load()
        },
        combine(fields) {
            let fieldsInd = fields.map(item => {
                let ind = this.customerGeo.features.findIndex(field => {
                    // return field.properties.fmid_land_id === item.landId
                    return field.properties.fieldId === item.fieldId
                })
                return ind
            })
            let remaining = this.customerGeo.features.filter((item,i) => !fieldsInd.includes(i))
            let removed = this.customerGeo.features.filter((item,i) => fieldsInd.includes(i))
            let combined = removed.reduce((acc, cur) => {
                return union(acc, cur)
            })
            combined.properties = {
                title: (removed.map(item => item.properties.title)).join(",")
            }
            remaining.unshift(combined)
            this.customerGeo.features = remaining
            this.setData(this.customerGeo)
            return this.calcAcres(combined)
        },
        setData(data, colorChange = true) {
            // this.customerGeo = null
            this.customerGeo = data
            this.customerGeo.features.forEach((item,ind) => {
                item.properties.fieldId = ind
                item.properties.title = item.properties.title ? item.properties.title : `${ind + 1}`
                if (colorChange) {
                    item.properties.color = item.properties.crop === 'Field Corn' ? "#ffff00" : item.properties.crop === 'Soybeans' ? "#008000" : "#808080"
                }
            })
            return this.customerGeo
        },
        mapClick(feature, data) {
            this.initialMapClick = true
            if (feature) {
                if (this.draw || this.add) return
                let fieldId = data.features[0].properties.fieldId
                if (!this.select) {
                    let box = bbox(this.customerGeo.features[fieldId])
                    this.$refs.map.actions.fitBounds(box, {
                        padding: {top: 20, bottom:20, left: 20, right: 20}
                    })
                }
                this.$emit("editField", fieldId)
            }
            else {
                if (this.draw) return
                
                if (this.add) {
                    this.$emit("prospectCoords", data.lngLat)
                }
                else if (this.prospect) {
                    this.customerGeo.features = []
                    this.markerCoords = [data.lngLat.lng, data.lngLat.lat]
                    this.$emit("prospectCoords", data.lngLat)
                }
            }
        },
        addDraw() {
            this.drawBox = new MapboxDraw({
                displayControlsDefault: false,
                // controls: {
                //     polygon: true,
                //     trash: true
                // },
                // defaultMode: 'draw_polygon'
            })
            this.$refs.map.map.addControl(this.drawBox)
            this.$refs.map.map.on("draw.create", this.updateArea)
            // this.$refs.map.map.on("draw.update", this.updateArea)
            // this.$refs.map.map.on("draw.delete", this.updateArea)
        },
        toggleAdd() {
            if (!this.add) {
                this.cursor = "crosshair"
                // this.prospect = true
            }
            else {
                this.cursor = "pointer"
                // this.prospect = false
            }
        },
        toggleDraw() {
            if (!this.draw) {
                this.drawBox.changeMode("draw_polygon")
                this.cursor = "crosshair"
            }
            else {
                this.drawBox.changeMode("simple_select")
                this.cursor = "pointer"
            }
        },
        calcAcres(val) {
            return Math.round(convertArea(area(val), "meters", "acres") * 100) / 100
        },
        updateArea(val) {
            let currentFeatures = JSON.parse(JSON.stringify(this.customerGeo.features))
            let { features } = val
            let newField = features[0]
            // let areaCalc = area(newField)
            // let acres = Math.round(convertArea(areaCalc, "meters", "acres") * 100) / 100
            let acres = this.calcAcres(newField)
            newField.properties = {
                title: "new field",
                acres
            }
            delete newField.id
            currentFeatures.unshift(newField)
            this.customerGeo.features = currentFeatures
            this.setData(this.customerGeo)
            this.$emit("pushField", acres)
            this.drawBox.deleteAll()
            this.cursor = "pointer"
        },
        addNewField(newField) {
            let currentFeatures = JSON.parse(JSON.stringify(this.customerGeo.features))
            newField.properties.title = "new field"
            currentFeatures.unshift(newField)
            this.customerGeo.features = currentFeatures
            this.setData(this.customerGeo)
        },
        async load() {
            if (this.loaded) return
            this.loaded = true
            this.$refs.map.map.on("click", "fill", e => {
                e.clicked = true
                this.mapClick(true, e)
            })
            this.$refs.map.map.on("click", e => {
                if (e.clicked) return
                this.mapClick(false, e)
            })

            if (!this.drawBox && !this.prospect) {
                this.addDraw()
            }
            
            if (this.customerGeo.features.length) {
                this.box = bbox(this.customerGeo)
                if (this.report) {
                    this.defaultMapSettings = await this.$refs.map.actions.fitBounds(this.box, {
                        padding: {top: 20, bottom:20, left: 20, right: 20},
                        linear: true
                    })
                }
                else {
                    this.defaultMapSettings = await this.$refs.map.actions.fitBounds(this.box, {
                        padding: {top: 20, bottom:20, left: 20, right: 20}
                    })
                }
            }
        },
        fitField(val) {
            // console.log("val", val)
            // let ind = this.customerGeo.features.findIndex(item => item.properties.fieldId === val)
            // let ind = this.fields.findIndex(item => item.id === val.id)
            // console.log("ind", ind)
            if (!this.customerGeo.features[val].geometry) return
            let box = bbox(this.customerGeo.features[val])
            this.$refs.map.actions.fitBounds(box, {
                padding: {top: 20, bottom:20, left: 20, right: 20}
            })
        },
        clickMe(data) {
            // if (this.growerIds.length) return
            if (this.draw || this.add) return

            let fieldId = data.mapboxEvent.features[0].properties.fieldId
            if (!this.select) {
                let box = bbox(this.customerGeo.features[fieldId])
                this.$refs.map.actions.fitBounds(box, {
                    padding: {top: 20, bottom:20, left: 20, right: 20}
                })
            }
            this.$emit("editField", fieldId)
        },
        geoJsonReady(val) {
            if (val.layerId === "name") {
                this.$emit("geoJsonReady", val)
            }  
        }
    },
    // beforeDestroy() {
    //     this.$store.commit("customer/emptyCustomer")
    // }
}
</script>

<style lang="scss">
    canvas{
        cursor: var(--cursor-type)
    }
</style>